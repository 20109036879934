import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, Button, Form, FormControl, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

const CreateTranslateButton = () => {
  return { __html: `
      <div id="google_translate_element"></div>
      <script type="text/javascript">
      console.log('test');
      function googleTranslateElementInit() {
        new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
      }
      </script>
      <script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
  ` }
}

const Header = ({ siteTitle }) => (
  
  <Navbar bg="light" variant="light">
  <Container>
    <Navbar.Brand href="/">
    <img src="/img/logo.jpg" alt="logo" width="40"/>
    </Navbar.Brand>   
      <Nav className="mr-auto">
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/services">Services</Nav.Link>
        {/*<Nav.Link href="/pricing">Pricing</Nav.Link>*/}
        <Nav.Link href="/portfolio">Portfolio</Nav.Link>
        <Nav.Link href="/about">About</Nav.Link>
        <Nav.Link href="/contact">Contact</Nav.Link>
      </Nav>
      <Form inline>
        <FormControl type="text" placeholder="Search" className="mr-sm-2" />
        <Button variant="outline-primary">Search</Button>
      </Form>
      <div style={{ margin: `auto`}} dangerouslySetInnerHTML={CreateTranslateButton()}/>
  </Container>
</Navbar>

)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
